<template>
  <div
    class="relative z-20 my-4 flex flex-col-reverse lg:block lg:bg-gray-100 lg:p-4"
  >
    <div class="container relative z-20 mx-auto w-full text-primary @container">
      <AuctionListFilter v-if="data.showFilter" />
      <div ref="parent" class="mx-auto flex flex-col">
        <AuctionListPlaceholder
          v-if="isInitialLoading"
          :size="data.amountActive ?? 10"
        />
        <h2
          v-if="!isInitialLoading && !dataToDisplay?.length && !error"
          class="p-2 md:p-0"
        >
          {{ $t('auction-list.no-auctions') }}
        </h2>
        <div v-for="(item, index) in dataToDisplay" :key="item.number">
          <template v-if="data.type === 'all'">
            <AuctionListNationality
              :prev-nationality="dataToDisplay[index - 1]?.nationality"
              :current-nationality="item.nationality"
              :index="index"
              :current-stage="item.stage"
              :prev-stage="dataToDisplay[index - 1]?.stage"
            />
            <AuctionListDate
              :prev-date="dataToDisplay[index - 1]?.startDate"
              :stage="item.stage"
              :current-date="item.startDate"
              :index="index"
            />
          </template>
          <AuctionListItem v-bind="item" />
        </div>
        <AuctionListSpinner v-if="isFetchingNextPage" />
        <div ref="threshold"></div>
        <div v-if="error" role="alert" class="py-4">
          {{ $t('auction-list.fetch-failed') }}
          <span class="text-xs">({{ error }})</span>
          <LazyElementsButton @click="recoverFromError">{{
            $t('auction-list.refetch')
          }}</LazyElementsButton>
        </div>
      </div>
    </div>
    <div class="px-2 md:px-0">
      <ElementsButton
        v-if="data?.buttonAuction"
        class="my-1 flex items-center justify-center lg:mt-4"
        :target="data.buttonAuction.newTab ? '_blank' : undefined"
        v-bind="data.buttonAuction"
        type="block"
        >{{ data.buttonAuction.text }}</ElementsButton
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useInfiniteQuery, useQueryClient } from '@tanstack/vue-query'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { storeToRefs } from 'pinia'
import type { AuctionFilterState } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import { useAuctionStore } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import { AUCTION_SOCKET_EVENTS } from '@autobid/ui/constants/AUCTION_SOCKET_EVENTS'
import { convertDataToDisplay } from '@autobid/ui/utils/auctions/convertDataToDisplay'
import { useInfiniteScroll } from '@autobid/ui/composables/useInfiniteScroll'
import type {
  AuctionsData,
  AuctionSocketEvent,
  Item
} from '@autobid/ui/types/components/AuctionsData'
import { getAuctionsQuery } from '@autobid/ui/utils/auctions/getAuctionsQuery'
import { useDebounce } from '@autobid/ui/composables/useDebounce'
import { STAGES } from '@autobid/ui/constants/STAGES'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useWebSocketStore } from '@autobid/nuxt-pinia-store/store/useWebSocketStore'
import { onMounted } from 'vue'
import { APP_NAME_ID_MAP } from '../../../constants/APP_NAME_ID_MAP'
import AuctionListPlaceholder from './AuctionListPlaceholder.vue'
import AuctionListItem from './item/AuctionListItem.vue'
import AuctionListNationality from './item/AuctionListItemNationality.vue'
import AuctionListDate from './item/AuctionListItemDate.vue'
import AuctionListFilter from './filter/AuctionListFilter.vue'
import AuctionListSpinner from './AuctionListSpinner.vue'

const [parent] = useAutoAnimate()
const threshold = ref<HTMLElement>()

interface Color {
  id: number
  name: string
}
interface ButtonAuction {
  id: number
  url: string
  text: string
  newTab: boolean
  color: Color
}

/**
 * @description type also decides about AuctionList(Nationality/Date) rendering
 * only when is "all" render these elements
 */
type Type = 'all' | 'active'

interface Data {
  type: Type
  amountActive: number
  showFilter: boolean
  apps: string[]
  filterByDepartment?: string
  buttonAuction?: ButtonAuction
}

interface Props {
  data?: Data
}

const props = defineProps<Props>()

const { $customFetch } = useCustomFetch()
const { locale, locales } = useI18n()
const query = getAuctionsQuery(locale.value, locales.value)
const { startListen, endListen } = useWebSocketStore()

provide('isMainPage', props.data?.type === 'active')

const auctionStoreKey = JSON.stringify(props)
provide('auctionStoreKey', auctionStoreKey)

type QueryKey = [
  key: string,
  lang: string,
  filters: AuctionFilterState,
  props: Props
]

interface FetchParams {
  pageParam: number
  queryKey: QueryKey
}

const getStartOfDay = (date: string) => {
  const startOfDay = new Date(date)
  startOfDay.setHours(0, 0, 0, 0)
  return startOfDay
}

const getEndOfDay = (date: string) => {
  const endOfDay = new Date(date)
  endOfDay.setHours(23, 59, 59, 999)
  return endOfDay
}

const formatStartDateRange = (
  startDateRange: AuctionFilterState['startDateRange']
) => {
  return {
    dateTo: startDateRange.dateTo
      ? getEndOfDay(startDateRange.dateTo).toISOString()
      : '',
    dateFrom: startDateRange.dateFrom
      ? getStartOfDay(startDateRange.dateFrom).toISOString()
      : ''
  }
}

const formatStoreFilter = (filters: AuctionFilterState) => {
  const { designations, startDateRange, descriptionTitles, ..._filter } =
    filters

  const formattedStartDateRange = formatStartDateRange(startDateRange)

  return {
    ..._filter,
    descriptionTitles: descriptionTitles.length ? descriptionTitles : undefined,
    designations: designations.length ? designations : undefined,
    ...formattedStartDateRange
  }
}

const findAppIds = (_apps: string[]) =>
  _apps?.map((app) => APP_NAME_ID_MAP[app.toLowerCase()])

const appIds = computed(() => findAppIds(props.data?.apps))
provide('appIds', appIds)

const fetchAuctions = async ({ pageParam = 0, queryKey }: FetchParams) => {
  const filter = queryKey[2]
  const props = queryKey[3]

  const department = props?.data?.filterByDepartment

  const filterFromStore =
    props.data.type === 'all' ? formatStoreFilter(filter) : {}
  const mergedAppIds = [
    ...new Set([...(filterFromStore?.appIds ?? []), ...(appIds.value ?? [])])
  ]

  const rawAuctionsData = await $customFetch<AuctionsData>('/api/backend', {
    method: 'POST',
    body: {
      queryApi: 'auctions',
      queryUrl: '/api/v1/query',
      query,
      variables: {
        stage: props.data.type === 'active' ? ['ONLINE'] : undefined,
        pageNumber: pageParam,
        pageSize: props?.data?.amountActive ?? 10,
        // filters from store:
        ...filterFromStore,
        // filters from props:
        department: department?.length ? department : undefined,
        appIds: mergedAppIds.length ? mergedAppIds : undefined,
        publicationStatus: ['PUBLISHED']
      }
    }
  })

  const auctions = rawAuctionsData?.data?.auctions

  if (rawAuctionsData?.errors?.length || !auctions) {
    const graphqlResponseError =
      rawAuctionsData?.errors?.map((item) => item?.message).join(',') ?? ''
    if (graphqlResponseError.length) {
      throw new Error(graphqlResponseError)
    }
    throw new TypeError('Unexpected response')
  }

  const { items, ...page } = auctions

  const dataToDisplay = convertDataToDisplay({
    data: items,
    lang: locale.value
  })

  return {
    auctions: dataToDisplay,
    page
  }
}

const { filter, isInitRender } = storeToRefs(useAuctionStore(auctionStoreKey))

const {
  error,
  refetch,
  data: auctions,
  fetchNextPage,
  isFetchingNextPage,
  isInitialLoading,
  dataUpdatedAt
} = useInfiniteQuery({
  queryKey: ['auctions', locale.value, filter, props],
  queryFn: (params) => fetchAuctions(params as unknown as FetchParams),
  getNextPageParam: (lastPage) => {
    const next = lastPage.page.itemPageOffset + 1
    return next < lastPage.page.itemPageCount ? next : undefined
  },
  refetchOnWindowFocus: false
})

if (!props?.data?.buttonAuction) {
  useInfiniteScroll(fetchNextPage, isFetchingNextPage, threshold)
}

const dataToDisplay = computed(() => {
  return (
    auctions.value?.pages
      ?.map((page) => page.auctions)
      .flat()
      ?.filter(Boolean) ?? []
  )
})

const queryClient = useQueryClient()

const SOCKET_CALLBACK_NAME = 'auction-list'
const auctionSocket = () => {
  const invalidate = () => queryClient.invalidateQueries(['auctions'])

  const invalidateFilter = () => {
    queryClient.invalidateQueries(['auctionListFilter', locale.value, appIds])
  }

  const isMainPage = props.data?.type === 'active'

  const remove = (e: AuctionSocketEvent) => {
    if (isMainPage) {
      invalidate()
      invalidateFilter()
      return
    }

    queryClient.setQueriesData(['auctions'], (item) => {
      const queryData = item as typeof auctions.value

      const newPages =
        queryData.pages?.map((page) => {
          const auctions = page.auctions.filter((auction) => {
            return auction.number !== e.context.auctionId
          })

          return {
            ...page,
            auctions
          }
        }) ?? []

      return { ...queryData, pages: newPages }
    })

    invalidateFilter()
  }

  const updateAuctionData = (auctionId: number, data: Partial<Item>) => {
    if (isMainPage && data.stage) {
      invalidate()
      invalidateFilter()
      return
    }

    queryClient.setQueriesData(['auctions'], (item) => {
      const queryData = item as typeof auctions.value

      const newPages =
        queryData.pages?.map((page) => {
          const auctions = page.auctions
            .map((auction) => {
              if (auction.number === auctionId) {
                return {
                  ...auction,
                  ...data
                }
              }
              return auction
            })
            .sort((a, b) => {
              return STAGES.indexOf(a.stage) - STAGES.indexOf(b.stage)
            })
          return {
            ...page,
            auctions
          }
        }) ?? []

      return { ...queryData, pages: newPages }
    })

    invalidateFilter()
  }

  startListen(AUCTION_SOCKET_EVENTS.hidden, SOCKET_CALLBACK_NAME, remove)
  startListen(AUCTION_SOCKET_EVENTS.withdraw, SOCKET_CALLBACK_NAME, remove)
  startListen(
    AUCTION_SOCKET_EVENTS.inPrepare,
    SOCKET_CALLBACK_NAME,
    (e: AuctionSocketEvent) => {
      updateAuctionData(e.context.auctionId, { stage: 'IN_PREPARATION' })
    }
  )
  startListen(
    AUCTION_SOCKET_EVENTS.finished,
    SOCKET_CALLBACK_NAME,
    (e: AuctionSocketEvent) => {
      updateAuctionData(e.context.auctionId, {
        stage: 'FINISHED',
        inActiveHotbidPhase: false
      })
    }
  )
  startListen(
    AUCTION_SOCKET_EVENTS.hotbidStart,
    SOCKET_CALLBACK_NAME,
    (event: { context: { auctionId: number } }) => {
      updateAuctionData(event.context.auctionId, { inActiveHotbidPhase: true })
    }
  )
}

const refreshStaleDataInOneReq = useDebounce(async () => {
  if (!auctions.value) {
    return
  }
  const now = Date.now()
  const isStale = now - dataUpdatedAt.value > 5 * 60 * 1000
  if (!isStale) {
    return
  }

  const result = await fetchAuctions({
    pageParam: 0,
    queryKey: [
      'auctions',
      locale.value,
      filter.value,
      {
        data: {
          ...props.data,
          amountActive:
            auctions.value.pages.length * (props.data.amountActive ?? 10)
        }
      }
    ]
  })

  const paginated = result.auctions.reduce((acc, current, index) => {
    const currIdx = Math.floor(index / (props.data.amountActive ?? 10))
    const currArr = acc[currIdx] ?? []

    currArr.push(current)
    acc[currIdx] = currArr
    return acc
  }, [])

  const queryData: typeof auctions.value = queryClient.getQueryData([
    'auctions',
    locale.value,
    filter,
    props
  ])

  const updatedQueryData =
    queryData.pages?.map((page, index) => ({
      ...page,
      auctions: paginated[index]
    })) ?? []

  queryClient.setQueryData(['auctions', locale.value, filter, props], {
    pages: updatedQueryData,
    pageParams: queryData.pageParams
  })
})

function recoverFromError() {
  if (!dataToDisplay.value?.length) {
    return refetch()
  }
  return fetchNextPage()
}

onMounted(() => {
  auctionSocket()
  if (!isInitRender.value) {
    refreshStaleDataInOneReq()
  }
})

onBeforeUnmount(() => {
  Object.values(AUCTION_SOCKET_EVENTS).forEach((event) => {
    endListen(event, SOCKET_CALLBACK_NAME)
  })
  isInitRender.value = false
})

onMounted(() => {
  addEventListener('visibilitychange', refreshStaleDataInOneReq)
})

onBeforeUnmount(() => {
  removeEventListener('visibilitychange', refreshStaleDataInOneReq)
})
</script>
