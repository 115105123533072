import type { Ref } from 'vue'
import { onMounted } from 'vue'

export function useInfiniteScroll<TPromise>(
  fn: () => Promise<TPromise>,
  isLoading: Ref<boolean>,
  threshold: Ref<HTMLElement>
) {
  const handleIntersection = async (entries: IntersectionObserverEntry[]) => {
    const target = entries[0]
    if (target.isIntersecting && !isLoading.value) {
      await fn()
    }
  }

  onMounted(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    })

    observer.observe(threshold.value)
  })
}
