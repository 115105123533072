import type { Locale } from '@autobid/ui/types/I18n'

export const getAuctionsQuery = (
  lang: string,
  locales: Locale[]
) => `query getAuctions($pageSize: Int!, $pageNumber: Int!, $stage: [AuctionStage!] = [], $publicationStatus: [AuctionStatus!], $dateFrom: String = "", $dateTo: String = "", $numbers: [Int] = [], $designations: [String!] = [], $descriptionTitles: [String!] = [], $countryIds: [Int] = [], $type: [AuctionType!] = [], $appIds: [Int] = [], $department: String = "", $includeNoPublishedItems: Boolean = false, $includeNotVisibleInList: Boolean = false, $includeHiddenFinished: Boolean = false){
  auctions(params: {pageSize: $pageSize, pageNumber: $pageNumber, appIds: $appIds, stage: $stage, publicationStatus: $publicationStatus, startDateRange: {dateFrom: $dateFrom, dateTo: $dateTo}, numbers: $numbers, designations:$designations, descriptionTitles: $descriptionTitles, countryIds: $countryIds, type: $type, department: $department, includeNoPublishedItems: $includeNoPublishedItems, includeNotVisibleInList: $includeNotVisibleInList, includeHiddenFinished: $includeHiddenFinished, lang: ${JSON.stringify(
    lang
  )}}) {
    itemPageCount
    itemPageOffset
    items {
      id
      descriptions {
        title {
          ${lang.toUpperCase()}
        }
        subtitleA {
          ${lang.toUpperCase()}
        }
        subtitleB {
          ${lang.toUpperCase()}
        }
        subtitleC {
          ${lang.toUpperCase()}
        }
      }
      notice {
         ${lang.toUpperCase()} {
          url
          label
        }
      }
      slug{
        default
        ${locales.map((locale) => locale.code.toUpperCase()).join(' ')}
      }
      appId
      image
      number
      restrictions{
        permissionRequired
        permittedCountriesIDs
      }
      nationality
      stage
      counters{
        publishedItems
      }
      designations
      country {
        name
        isoCode
      }
      department {
        name
        id
      }
      endDate
      startDate
      type
      logos {
        url
        alt
      }
      catalog {
        type
        url
      }
      activeStatusesNames
      publicationInfo {
        status
        dataHash {
          auction
        }
      }
      barometerBanner
      inActiveHotbidPhase
      pauseDate
      isPaused
    }
  }
}`
